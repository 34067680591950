.Attribution {
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 4px 2px;
    z-index: 10;
}

.Attribution a,
.Attribution span {
    color: white;
    text-decoration: none;
    margin: 0 2px 4px;
}

.Attribution span {
    pointer-events: none;
}

.Attribution a:hover {
    color: rgb(53, 175, 109);
}
.BackgroundWrapper {
    background-color: black;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    color: white;
    font-family: Helvetica, Arial, sans-serif;
    text-align: center;
    padding-top: 30%;
}

.Background p {
    position: relative;
    padding: 5px;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 12px;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.75);
}

.BearingIndicator {
    bottom: 28px;
    height: 66px;
    pointer-events: none;
    position: absolute;
    right: 11px;
    width: 56px;
    z-index: 10;
}

.BearingIndicatorBackground {
    opacity: 0.5;
    pointer-events: auto;
}

.BearingIndicatorBackgroundRectangle {
    background: #242528;
    height: 12px;
    left: 23px;
    position: absolute;
    top: 0;
    width: 10px;
}

.BearingIndicatorBackgroundCircle {
    background: #242528;
    border-radius: 50%;
    bottom: 0;
    height: 56px;
    position: absolute;
    width: 56px;
}

.BearingIndicatorNorth {
    background: white;
    height: 6px;
    left: 27px;
    position: absolute;
    top: 4px;
    width: 2px;
}
.Cover {
    background-color: rgba(0, 0, 0, 0.5);
    bottom: 0;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 100;
    position: absolute;
    overflow: hidden;
}

.CoverBackground {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    background-position: center;
    background-size: cover;
    bottom: 0;
    left: 0;
    margin-left: -3px;
    right: 0;
    top: 0;
    transform: translateZ(0);
    transition: transform 5s ease-out, background-image .2s ease-in-out;
    z-index: -10;
    position: absolute;
    margin-right: -3px;
}

.Cover:hover .CoverBackground {
    transform: scale(1.1);
}

.Cover.CoverLoading .CoverBackground{
    transform: scale(1);
    transition: transform .4s ease-out;
}

.CoverBackgroundGradient {
    background: linear-gradient(-180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
    bottom: 0;
    height: 110px;
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    transition: opacity .2s ease-in-out;
    z-index: 1;
}

.CoverLogo {
    background-size: cover;
    background: url(./cover-logo.svg);
    bottom: 30px;
    height: 32px;
    left: 30px;
    position: absolute;
    transition: opacity .2s ease-in-out;
    width: 128px;
    z-index: 300;
}

@media screen and (max-width: 560px) {
  .CoverLogo {
      display: none;
  }
}

.Cover.CoverDone > .CoverBackground {
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 100;
}

.CoverDone {
    visibility: hidden;
    opacity: 0;
}

.CoverButton {
    background-color: transparent;
    border-radius: 999px;
    border: 1px solid white;
    bottom: 30px;
    color: white;
    font-size: 14px;
    font-weight: 300;
    height: 34px;
    left: 50%;
    letter-spacing: 1px;
    margin-left: -70px;
    padding: 5px 30px 7px;
    position: absolute;
    transition: all .2s ease-in-out;
    width: 140px;
}

.CoverButton:hover {
    background-color: white;
    color: #5D6671;
    cursor: pointer;
}

.CoverButton:focus {
    outline: 0;
}

.CoverButtonIcon {
    background-size: contain;
    background: url(./pano.svg);
    display: inline-block;
    height: 60px;
    width: 60px;
}

.Spinner {
  animation: rotate 3s linear infinite;
  background-image: url('./spinner.svg');
  background-repeat: no-repeat;
  background-size: 100%;
  height: 32px;
  left: 50%;
  margin-left: -16px;
  margin-top: -16px;
  position: absolute;
  top: 50%;
  width: 32px;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

.Cover.CoverLoading .CoverButton,
.Cover.CoverLoading .CoverLogo,
.Cover.CoverLoading .CoverBackgroundGradient {
    opacity: 0;
}
.Debug {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 30em;
    background-color: lime;
    font-size: 12px;
    padding: 1em;
    font-family: Input, Menlo, monospace;
    z-index: 50;
}

.DebugButton {
    background-color: lime;
    color: white;
    border: 1px solid white;
    border-radius: 2px;
    cursor: pointer;
    cursor: hand;
    z-index: 50;
}

.DebugButtonFixed {
    position: absolute;
    bottom: 2px;
    left: 2px;
}
.DirectionsPerspective {
    display: block;
    position: absolute;
    pointer-events: none;
    transform-style: preserve-3d;
    z-index: 10;
}

.DirectionsCircle,
.DirectionsCircleSequence,
.DirectionsCircleDisabled,
.DirectionsCircleHighlight,
.DirectionsCircleSequenceHighlight {
    position: absolute;
    border-radius: 50%;
}

.DirectionsCircle,
.DirectionsCircleSequence,
.DirectionsCircleHighlight,
.DirectionsCircleSequenceHighlight {
    pointer-events: auto;
}

.DirectionsCircle:hover,
.DirectionsCircleSequence:hover,
.DirectionsCircleHighlight:hover,
.DirectionsCircleSequenceHighlight:hover {
    cursor: pointer;
    cursor: hand;
}

.DirectionsCircle,
.DirectionsCircleSequence {
    background: rgba(0, 0, 0, 0);
}

.DirectionsCircleDisabled {
    background: rgba(0, 0, 0, 0);
    z-index: -1;
}

.DirectionsCircleHighlight,
.DirectionsCircleSequenceHighlight {
    background: rgba(0, 0, 0, 0.4);
}

.DirectionsArrowStep,
.DirectionsArrowPano,
.DirectionsArrowDisabled {
    position: absolute;
    top: 44%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 75%;
    height: 44.0775%;
    pointer-events: none;
}

.DirectionsArrowStep,
.DirectionsArrowDisabled {
    background: url(./pointer-white.svg);
    background-repeat: no-repeat;
}

.DirectionsArrowDisabled {
    opacity: 0.2;
    z-index: -1;
}

.DirectionsArrowPano {
    background: url(./pointer-wheat.svg);
    background-repeat: no-repeat;
}

.TurnCircle,
.TurnCircleSequence,
.TurnCircleHighlight,
.TurnCircleSequenceHighlight {
    position: absolute;
    border-radius: 50%;
    pointer-events: auto;
}

.TurnCircle,
.TurnCircleSequence {
    background: rgba(0, 0, 0, 0);
}

.TurnCircleHighlight,
.TurnCircleSequenceHighlight {
    background: rgba(0, 0, 0, 0.4);
}

.TurnCircle:hover,
.TurnCircleSequence:hover,
.TurnCircleHighlight:hover,
.TurnCircleSequenceHighlight:hover {
    cursor: pointer;
    cursor: hand;
}

.TurnLeft,
.TurnRight,
.TurnAround {
    position: absolute;
    top: 50%;
    pointer-events: none;
    filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.8));
}

.TurnLeft,
.TurnRight {
    width: 58.794%;
    height: 70%;
}

.TurnAround {
    left: 50%;
    width: 75%;
    height: 63.2475%;
}

.TurnLeft {
    left: 45%;
    background: url(./turn.svg);
    background-repeat: no-repeat;
    transform: translate(-50%, -50%);
}

.TurnRight {
    left: 55%;
    background: url(./turn.svg);
    background-repeat: no-repeat;
    transform: translate(-50%, -50%) scaleX(-1);
}

.TurnAround {
    background: url(./turn-around.svg);
    background-repeat: no-repeat;
    transform: translate(-50%, -50%);
}

@media (hover: hover) {
    .DirectionsCircle:hover,
    .DirectionsCircleSequence:hover {
        background: rgba(0, 0, 0, 0.4);
    }

    .TurnCircle:hover,
    .TurnCircleSequence:hover {
        background: rgba(0, 0, 0, 0.4);
    }
}
.Loading {
    width: 100%;
    height: 2px;
    z-index: 20;
    position: absolute;
    top: 0;
}

.LoadingBar {
    height: 4px;
    position: relative;
    background-color: rgba(53, 175, 109, 0.8);
    transition: opacity 100ms;
}
.NavigationComponent {
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 10;
}

.Direction {
    position: absolute;
    width: 36px;
    height: 36px;
    display: block;
    background: url(./arrow-up-white.svg);
    background-size: contain;
    background-repeat: no-repeat;
    pointer-events: auto;
    cursor: pointer;
}

.DirectionForward {
    top: 5px;
    left: 50%;
    transform: translate(-50%, 0);
    filter: drop-shadow(0 1px 0 rgba(0, 0, 0, 1));
}

.DirectionBackward {
    bottom: 5px;
    left: 50%;
    transform: translate(-50%, 0) rotate(180deg);
    filter: drop-shadow(0 -1px 0 rgba(0, 0, 0, 1));
}

.DirectionLeft {
    left: 5px;
    top: 50%;
    transform: translate(0, -50%) rotate(-90deg);
    filter: drop-shadow(-1px 0 0 rgba(0, 0, 0, 1));
}

.DirectionRight {
    right: 5px;
    top: 50%;
    transform: translate(0, -50%) rotate(90deg);
    filter: drop-shadow(1px 0 0 rgba(0, 0, 0, 1));
}

.DirectionTurnleft {
    left: 5px;
    top: 5px;
    transform: rotate(-45deg);
    filter: drop-shadow(-1px 1px 0 rgba(0, 0, 0, 1));
}

.DirectionTurnright {
    right: 5px;
    top: 5px;
    transform: rotate(45deg);
    filter: drop-shadow(1px 1px 0 rgba(0, 0, 0, 1));
}

.DirectionTurnaround {
    left: 5px;
    bottom: 5px;
    transform: rotate(-135deg);
    filter: drop-shadow(-1px -1px 0 rgba(0, 0, 0, 1));
}
.mapillaryjs-popup {
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
    display: flex;
}

.mapillaryjs-popup-content,
.mapillaryjs-popup-content-clean {
    position: relative;
    pointer-events: auto;
}

.mapillaryjs-popup-content {
    padding: 5px 10px;
    background: white;
    border: 1px solid black;
    border-radius: 2px;
}

.mapillaryjs-popup-float-bottom,
.mapillaryjs-popup-float-bottom-left,
.mapillaryjs-popup-float-bottom-right {
    flex-direction: column;
}

.mapillaryjs-popup-float-top,
.mapillaryjs-popup-float-top-left,
.mapillaryjs-popup-float-top-right {
    flex-direction: column-reverse;
}

.mapillaryjs-popup-float-left {
    flex-direction: row-reverse;
}

.mapillaryjs-popup-float-right {
    flex-direction: row;
}

.mapillaryjs-popup-tip,
.mapillaryjs-popup-tip-inner {
    position: relative;
    z-index: 1;
    width: 0;
    height: 0;
    border: 7px solid transparent;
}

.mapillaryjs-popup-float-top .mapillaryjs-popup-tip {
    align-self: center;
    border-top-color:black;
    border-bottom: none;
}

.mapillaryjs-popup-float-top .mapillaryjs-popup-tip-inner {
    position: relative;
    top: -8px;
    left: -7px;
    border-top-color: white;
    border-bottom: none;
}

.mapillaryjs-popup-float-top-right .mapillaryjs-popup-tip {
    align-self: flex-start;
    border-top-color:black;
    border-bottom: none;
    border-left: none;
}

.mapillaryjs-popup-float-top-right .mapillaryjs-popup-tip-inner {
    position: relative;
    top: -9px;
    left: 1px;
    border-top-color: white;
    border-bottom: none;
    border-left: none;
}

.mapillaryjs-popup-float-top-left .mapillaryjs-popup-tip {
    align-self: flex-end;
    border-top-color:black;
    border-bottom: none;
    border-right: none;
}

.mapillaryjs-popup-float-top-left .mapillaryjs-popup-tip-inner {
    position: relative;
    top: -9px;
    left: -8px;
    border-top-color: white;
    border-bottom: none;
    border-right: none;
}

.mapillaryjs-popup-float-bottom .mapillaryjs-popup-tip {
    align-self: center;
    border-top: none;
    border-bottom-color: black;
}

.mapillaryjs-popup-float-bottom .mapillaryjs-popup-tip-inner {
    position: relative;
    bottom: -1px;
    left: -7px;
    border-top: none;
    border-bottom-color: white;
}

.mapillaryjs-popup-float-bottom-right .mapillaryjs-popup-tip {
    align-self: flex-start;
    border-bottom-color:black;
    border-top: none;
    border-left: none;
}

.mapillaryjs-popup-float-bottom-right .mapillaryjs-popup-tip-inner {
    position: relative;
    bottom: -2px;
    left: 1px;
    border-bottom-color: white;
    border-top: none;
    border-left: none;
}

.mapillaryjs-popup-float-bottom-left .mapillaryjs-popup-tip {
    align-self: flex-end;
    border-bottom-color:black;
    border-top: none;
    border-right: none;
}

.mapillaryjs-popup-float-bottom-left .mapillaryjs-popup-tip-inner {
    position: relative;
    bottom: -2px;
    left: -8px;
    border-bottom-color: white;
    border-top: none;
    border-right: none;
}

.mapillaryjs-popup-float-left .mapillaryjs-popup-tip {
    align-self: center;
    border-left-color: black;
    border-right: none;
}

.mapillaryjs-popup-float-left .mapillaryjs-popup-tip-inner {
    position: relative;
    top: -7px;
    left: -8px;
    border-left-color: white;
    border-right: none;
}

.mapillaryjs-popup-float-right .mapillaryjs-popup-tip {
    align-self: center;
    border-right-color: black;
    border-left: none;
}

.mapillaryjs-popup-float-right .mapillaryjs-popup-tip-inner {
    position: relative;
    top: -7px;
    left: 1px;
    border-right-color: white;
    border-left: none;
}

.mapillaryjs-popup-float-top-left .mapillaryjs-popup-content {
    border-bottom-right-radius: 0;
}

.mapillaryjs-popup-float-top-right .mapillaryjs-popup-content {
    border-bottom-left-radius: 0;
}

.mapillaryjs-popup-float-bottom-left .mapillaryjs-popup-content {
    border-top-right-radius: 0;
}

.mapillaryjs-popup-float-bottom-right .mapillaryjs-popup-content {
    border-top-left-radius: 0;
}
.RouteFrame {
    background-color: rgba(0, 0, 0, 0.5);
    outline: 1px solid rgba(255, 255, 255, 0.5);
    width: 80%;
    height: 40px;
    text-align: center;
    color: white;
    margin: 0 auto;
    position: absolute;
    z-index: 20;
    bottom: 30%;
    left: 10%;
}

.RouteFrame p {
    margin: 0;
    padding: 10px;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 14px;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.75);
}
.SequenceContainer {
    position: absolute;
    left: 50%;
    top: 14px;
    transform: translate(-50%, 0);
    display: flex;
    z-index: 10;
}

.SequencePlay,
.SequencePlayDisabled,
.SequenceStepNext,
.SequenceStepNextDisabled,
.SequenceStepNextHighlight,
.SequenceStepPrev,
.SequenceStepPrevDisabled,
.SequenceStepPrevHighlight {
    display: flex;
    flex: auto;
    align-items: center;
    justify-content: center;
}

.SequencePlay,
.SequencePlayDisabled,
.SequenceStepNext,
.SequenceStepNextDisabled,
.SequenceStepPrev,
.SequenceStepPrevDisabled {
    background: rgba(36, 37, 40, 0.3);
}

.SequenceStepNextHighlight,
.SequenceStepPrevHighlight {
    background: rgba(36, 37, 40, 0.6);
}

.SequenceStepNext,
.SequenceStepNextDisabled,
.SequenceStepNextHighlight {
    order: 2;
}

.SequenceStepPrev,
.SequenceStepPrevDisabled,
.SequenceStepPrevHighlight {
    order: 0;
}

.SequencePlay,
.SequencePlayDisabled {
    order: 1;
}

.SequencePlay:hover,
.SequenceStepNext:hover,
.SequenceStepNextHighlight:hover,
.SequenceStepPrev:hover,
.SequenceStepPrevHighlight:hover {
    cursor: pointer;
    cursor: hand;
}

.SequenceStepNext,
.SequenceStepNextDisabled,
.SequenceStepNextHighlight {
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
}

.SequenceStepPrev,
.SequenceStepPrevDisabled,
.SequenceStepPrevHighlight {
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
}

.Play.SequenceComponentIcon,
.PlayDisabled.SequenceComponentIcon {
    background-image: url('./stepper-play.svg');
}

.Stop.SequenceComponentIcon,
.StopDisabled.SequenceComponentIcon {
    background-image: url('./stepper-stop.svg');
}

.SequenceStepPrev .SequenceComponentIcon,
.SequenceStepPrevDisabled .SequenceComponentIcon,
.SequenceStepPrevHighlight .SequenceComponentIcon {
    background-image: url('./stepper-left.svg');
}

.SequenceStepNext .SequenceComponentIcon,
.SequenceStepNextDisabled .SequenceComponentIcon,
.SequenceStepNextHighlight .SequenceComponentIcon {
    background-image: url('./stepper-right.svg');
}

.PlayDisabled.SequenceComponentIcon,
.SequenceStepNextDisabled .SequenceComponentIcon,
.SequenceStepPrevDisabled .SequenceComponentIcon,
.StopDisabled.SequenceComponentIcon {
    opacity: 0.35;
}

.SequenceComponentIcon {
    background-size: contain;
    background-repeat: no-repeat;
    width: 50%;
    padding-bottom: 50%;
}

@media (hover: hover) {
    .SequencePlay:hover,
    .SequenceStepPrev:hover,
    .SequenceStepNext:hover {
        background: rgba(36, 37, 40, 0.6);
    }
}
.SliderWrapper {
    text-align: center;
    position: absolute;
    bottom: 40px;
    left: 0;
    pointer-events: none;
    right: 0;
    z-index: 20;
}

.SliderControl {
    max-width: 60%;
    padding: 4px;
    box-sizing: border-box;
    display: block;
    width: 100%;
    margin: 0 auto;
    marginTop: 10px;
    cursor: pointer;
    color: inherit;
    background-color: rgba(255, 255, 255, 0.20);
    background-clip: 'content-box';
    border-radius: 999px;
    -webkit-appearance: none;
    appearance: none;
    pointer-events: auto;
}

.SliderControl::-webkit-slider-thumb {
    width: 22px;
    height: 22px;
    background: #474A4E;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.50);
    border: 2px solid white;
    border-radius: 999px;
    -webkit-appearance: none;
}

.SliderControl::-moz-range-thumb {
    width: 22px;
    height: 22px;
    background: #474A4E;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.50);
    border: 2px solid white;
    border-radius: 999px;
    appearance: none;
}

.SliderControl:focus {
    outline: none;
}
.mapillary-js.component-tag-create .mapillary-js-interactive {
    cursor: crosshair;
}

.TagSymbol {
    cursor: pointer;
}

.TagMover,
.TagResizer {
    transform: translate(-50%, -50%);
    pointer-events: auto;
}

.TagMover {
    border-radius: 7px;
    width: 14px;
    height: 14px;
    opacity: 0.2;
    cursor: move;
}

.TagMover:hover {
    opacity: 0.5;
}

.TagResizer {
    border-radius: 7px;
    width: 14px;
    height: 14px;
    opacity: 0;
    cursor: crosshair;
}

.TagResizer:hover {
    opacity: 0.3;
}

.TagVertex {
    transform: translate(-50%, -50%);
    pointer-events: none;
    border-radius: 3px;
    width: 6px;
    height: 6px;
}

.TagInteractor,
.TagCompleter,
.TagSpotInteractor {
    transform: translate(-50%, -50%);
    pointer-events: auto;
    border-radius: 7px;
    width: 14px;
    height: 14px;
    opacity: 0;
}

.TagInteractor,
.TagCompleter {
    cursor: pointer;
}

.TagSpotInteractor {
    cursor: move;
}

.TagCompleter {
    background: limegreen;
}

.TagInteractor {
    background: orangered;
}

.TagCompleter:hover,
.TagInteractor:hover {
    opacity: 0.6;
}

.TagSpotInteractor:hover {
    opacity: 0.3;
}
.mapillary-js {
    background-color: #202020;
    position: relative;
    overflow: hidden;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}

.mapillary-js-interactive {
     cursor: grab;
}

.mapillary-js-interactive:active {
     cursor: grabbing;
}

.mapillary-js .mapillary-js-dom {
    user-select: none;
    position: relative;
    height: 100%;
    overflow: hidden;
    pointer-events: none;
}

.mapillary-js-dom .domRenderer {
    pointer-events: auto;
}
